import { render, staticRenderFns } from "./FormEntrySheet.vue?vue&type=template&id=77549ba8&scoped=true&"
import script from "./FormEntrySheet.vue?vue&type=script&lang=js&"
export * from "./FormEntrySheet.vue?vue&type=script&lang=js&"
import style0 from "./FormEntrySheet.vue?vue&type=style&index=0&id=77549ba8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77549ba8",
  null
  
)

export default component.exports
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChatMessage,QAvatar});
